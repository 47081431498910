@import 'theme.scss';
@import '@ballotready/sparkles/dist/tokens';

.Footer {
  background: var(--color-primary);
  color: $color-white;
  font-size: $text-md;
  padding: 3rem $space-xs;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 10;

  a {
    color: var(--color-light);
  }

  a:active,
  a:focus,
  a:hover {
    color: var(--color-light);
  }
}

.footerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerDisclaimer {
  background: $color-white;
  border-radius: 0.25rem;
  color: $color-black;
  margin: 0 1rem 2rem;
  max-width: 40rem;
  padding: $padding-cozy;

  a {
    color: var(--link-color);
  }

  a:hover {
    color: var(--link-color--hover);
  }
}

.footerLinks {
  list-style: none;
  padding: 0;
}

.footerLinks > li {
  margin-bottom: $margin-cozy;
}

.footerLinks > li a {
  color: $color-white;
  font-weight: bold;
  text-decoration: none;
}

.footerLocale {
  align-items: center;
  display: flex;
  margin-top: 1.5rem;
}

.footerLocale svg {
  margin-right: $margin-compact;
}

.footerLocaleSwitcher {
  margin-left: $margin-compact;
}

@media (min-width: 768px) {
  .footerLinks > li {
    display: inline-block;
    margin-bottom: 0;
    padding-left: $padding-cozy;
    padding-right: $padding-cozy;
  }

  .footerWithSidebar {
    margin-left: 18rem;
    width: calc(100% - 18rem);
  }
}

@media (min-width: 992px) {
  .footerWithSidebar {
    margin-left: 22rem;
    width: calc(100% - 22rem);
  }
}

@media print {
  .Footer {
    display: none;
  }
}
